import React, { useState } from 'react';
import './App.css';
import ProductList from './components/ProductList';
import Cart from './components/Cart';

const productsData = [
  { id: 1, name: 'Laptop', price: 999.99, image: 'https://via.placeholder.com/150' },
  { id: 2, name: 'Smartphone', price: 499.99, image: 'https://via.placeholder.com/150' },
  { id: 3, name: 'Headphones', price: 199.99, image: 'https://via.placeholder.com/150' },
];

function App() {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (product) => {
    setCartItems([...cartItems, product]);
  };

  const removeFromCart = (productId) => {
    setCartItems(cartItems.filter(item => item.id !== productId));
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Electronics Store</h1>
        <ProductList products={productsData} addToCart={addToCart} />
        <Cart cartItems={cartItems} removeFromCart={removeFromCart} />
      </header>
    </div>
  );
}

export default App;