import React, { useState } from 'react';
import './ProductList.css';

const ProductList = ({ products, addToCart }) => {
  return (
    <div className="product-list">
      {products.map(product => (
        <div key={product.id} className="product-item">
          <h3 className="product-name">{product.name}</h3>
          <p className="product-price">${product.price}</p>
          <button onClick={() => addToCart(product)} className="add-to-cart-button">Add to Cart</button>
        </div>
      ))}
    </div>
  );
};

export default ProductList;